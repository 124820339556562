import * as React from "react"
import { Email } from "react-obfuscate-email"
import { contactPageData } from "components/Data/ContactPageData"
import { contactDetails } from "components/Data/ContactDetails"
// import PropTypes from "prop-types"
// import { graphql, Link } from "gatsby"
import styled from "styled-components"
import SeoComponent from "components/SeoComponent"
import Form from "components/Form"
import ContactForm from "components/Forms/Contact"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import FacebookIcon from "../../content/assets/icon-facebook.png"
import LinkedInIcon from "../../content/assets/icon-linkedin.png"
import AddressIcon from "../../content/assets/icon-address.svg"
import EmailIcon from "../../content/assets/icon-email.svg"

const PageContentWrapper = styled.div`
  padding: 0 0 80px 0;
`

const ContactDetails = styled.div`
  padding-bottom: 60px;
  i {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: block;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;

    @media screen and (max-width: 419px) {
      margin-right: 0;
      margin-bottom: 12px;
    }
    &.email {
      background-image: url(${EmailIcon});
    }
    &.address {
      background-image: url(${AddressIcon});
    }
    &.Facebook {
      background-image: url(${FacebookIcon});
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    }
    &.LinkedIn {
      background-image: url(${LinkedInIcon});
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  ul li {
    display: inline-block;
    margin: 0 4px;
  }

  @media screen and (max-width: 419px) {
    text-align: center;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 15px;
    justify-content: center;

    i {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
    }
  }

  @media screen and (min-width: 420px) {
    p {
      flex-direction: row;
      align-items: center;
      display: flex;
    }

    ul li {
      margin-left: 0;
    }

    i {
      margin-bottom: 0;
      display: block;
    }
  }
`

const ContactPage = props => {
  const page = contactPageData

  return (
    <Layout pageName="contact">
      <SeoComponent
        title="Contact"
        description="Contact WINR Data if you'd like to have an API demo or have us review your privacy policies for suitability."
      />
      {page && page.page_header && (
        <PageHeader fields={page.page_header} pageName="contact" />
      )}

      <div className="container">
        <PageContentWrapper>
          <div className="row">
            <div className="col md-6">
              <ContactDetails>
                <p>
                  <i aria-hidden="true" className="address"></i>{" "}
                  <span>{contactDetails.address}</span>
                </p>
                <p>
                  <i aria-hidden="true" className="email"></i>
                  <span>
                    <Email
                      email={contactDetails.email}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="📧 Email us"
                    />
                  </span>
                </p>
                <ul>
                  {contactDetails.social_links.map((node, index) => (
                    <li key={index}>
                      <a
                        className="social-link"
                        href={node.link}
                        target="_blank"
                        title={node.icon}
                        rel="noopener noreferrer"
                      >
                        <i aria-hidden="true" className={node.icon}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </ContactDetails>
            </div>
            <div className="col md-6">
              <Form formComponent={ContactForm} />
            </div>
          </div>
        </PageContentWrapper>
      </div>
    </Layout>
  )
}

export default ContactPage
