import React, { Component } from "react"
import styled from "styled-components"
import posed from "react-pose"

const FormStyled = styled(
  posed.div({
    show: {
      opacity: 1,
      x: 0,
    },
    hide: {
      opacity: 0,
      x: "100px",
      transition: {
        duration: 100,
      },
      applyAtEnd: { visibility: "hidden", pointerEvents: "none" },
    },
  })
)`
  position: relative;
  margin-bottom: ${props => props.theme.spacing.base};
`

const Message = styled(
  posed.p({
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        duration: 100,
        stiffness: 100,
      },
    },
    hide: {
      opacity: 0,
      x: "-100px",
    },
  })
)`
  opacity: 0;

  && {
    position: absolute;
    font-size: ${props => props.theme.text.md};
  }
`

export default class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
    }
  }

  formSubmitted = val => {
    this.setState({
      submitted: val,
    })
  }

  render() {
    return (
      <>
        <Message
          initialPose="hide"
          pose={this.state.submitted ? "show" : "hide"}
        >
          Thanks for contacting WINR Data. <br />We'll get back to you as soon as possible.{" "}
        </Message>
        <FormStyled pose={this.state.submitted ? "hide" : "show"}>
          <this.props.formComponent
            formSubmitted={this.formSubmitted}
            pageTitle={this.props.pageTitle}
          />
        </FormStyled>
      </>
    )
  }
}
