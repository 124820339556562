import React from "react"
import styled from "styled-components"
import { withTheme } from "styled-components"
import FeatureTitle from "components/FeatureTitle"
import device from "utils/media"
import { underline } from "utils/mixins"

export const PageHeaderStyled = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.lg};
  padding-top: ${props =>
    props.noPadding
      ? 0
      : props.textStyle === "jumbo"
      ? "180px"
      : props.theme.spacing.header_mobile};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  @media ${device.sm} {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
  position: relative;
  z-index: 19;

  @media ${device.md} {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    padding-top: ${props =>
      props.noPadding
        ? 0
        : props.textStyle === "jumbo"
        ? "180px"
        : props.theme.spacing.header};
  }

  &.no-bottom-padding {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
`

export const HeadingInner = styled.div`
  position: relative;
  z-index: 10;
  @media ${device.xl} {
    padding-top: ${props => (props.pageName === "contact" ? "50px" : "0")};
    padding-bottom: ${props => (props.pageName === "contact" ? "50px" : "0")};
  }
`

export const Heading = styled(FeatureTitle)`
  ${props =>
    props.textStyle === "jumbo"
      ? `font-size: ${props.theme.text.md_2}`
      : `font-size: 30px`};
  font-weight: 900;
  line-height: ${props => (props.textStyle === "jumbo" ? 1 : 1.3)};
  margin-bottom: ${props =>
    props.hasSubheading ? props.theme.spacing.xs : ""};

  @media ${device.sm} {
    ${props =>
      props.textStyle === "jumbo"
        ? `font-size: ${props.theme.text.md_3}`
        : `font-size: 30px`};
  }

  @media ${device.md} {
    ${props =>
      props.textStyle === "jumbo" ? `font-size: ${props.theme.text.xl_2}` : ""};
    margin-bottom: ${props =>
      props.hasSubheading ? props.theme.spacing.xs : ""};
  }

  @media ${device.xl} {
    ${props =>
      props.textStyle === "jumbo" ? `font-size: ${props.theme.text.xxxl}` : ""};
    margin-bottom: ${props =>
      props.hasSubheading ? props.theme.spacing.base : ""};
  }
`

const SubHeading = styled.h3`
  font-size: ${props => props.theme.text.base_2};
  font-family: ${props => props.theme.font.body};
  line-height: 1.5;
  font-weight: 400;

  @media ${device.md} {
    font-size: ${props => props.theme.text.base_2};
    max-width: 1080px;
  }

  @media ${device.xl} {
    font-size: ${props => props.theme.text.md};
  }

  a {
    ${underline};
    color: ${props => props.theme.color.pink};

    &:hover {
      color: ${props => props.theme.color.purple};
    }

    &::after {
      height: 3px;
    }
  }
`

const PageHeader = ({ fields, pageName, className }) => {
  return (
    fields &&
    fields.heading && (
      <PageHeaderStyled className={className} pageName={pageName}>
        <HeadingInner className="container x-large" pageName={pageName}>
          <div className="row">
            <div className="col lg-10">
              <Heading
                textStyle={fields.style}
                hasSubheading={fields.subheading}
              >
                <span dangerouslySetInnerHTML={{ __html: fields.heading }} />
              </Heading>
              {fields.subheading && (
                <SubHeading
                  dangerouslySetInnerHTML={{ __html: fields.subheading }}
                />
              )}
            </div>
          </div>
        </HeadingInner>
      </PageHeaderStyled>
    )
  )
}

export default withTheme(PageHeader)
