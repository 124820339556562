const contactPageData = {
  page_header: {
    heading: `Let's Work Together.`,
    style: "jumbo",
    subheading: `<p>If you'd like to have an API demo or have us review your privacy policies for suitability, then please touch base and let's see if we can work together.</p>
`,
  },
}

export { contactPageData }
