import React, { Component } from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import Reaptcha from "reaptcha"
import { FancyInput, TextArea } from "components/FancyInput"
import { FormSubmit } from "components/FormInputs"

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string().required("Required"),
})

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: null,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onReCaptchaVerify = this.onReCaptchaVerify.bind(this)
    this.onReCaptchaExpire = this.onReCaptchaExpire.bind(this)
  }

  handleSubmit = (values, setSubmitting, resetForm) => {
    if (!this.state.token) {
      this.props.formSubmitted(false)
      setSubmitting(false)
      alert("Error: Invalid Captcha")
      return
    }

    axios({
      method: "post",
      url: `https://formspree.io/f/xlezgder`,
      headers: { "Content-Type": "application/json" },
      data: {
        Name: values.name,
        Company: values.company,
        Work_Email: values.email,
        Phone: values.phone,
        Subject: values.subject,
        Message: values.message,
      },
    })
      .then(response => {
        console.log(response)
        this.props.formSubmitted(true)
        setSubmitting(false)
        resetForm()
      })
      .catch(error => {
        console.log(error)
        this.props.formSubmitted(false)
        setSubmitting(false)
      })
  }

  onReCaptchaVerify = value => {
    if (value) {
      console.log("Captcha verified")
      this.setState({ token: value })
    }
  }

  onReCaptchaExpire = () => {
    console.log("Captcha expired")
    this.setState({ token: null })
  }

  render() {
    return (
      <Formik
        initialValues={{
          name: "",
          company: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          this.handleSubmit(values, setSubmitting, resetForm)
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className="row">
              <div className="col md-6">
                <Field
                  name="name"
                  component={FancyInput}
                  placeholder="Name *"
                />
              </div>

              <div className="col md-6">
                <Field
                  name="company"
                  component={FancyInput}
                  placeholder="Company"
                />
              </div>

              <div className="col md-6">
                <Field
                  name="email"
                  component={FancyInput}
                  placeholder="Work Email *"
                />
              </div>

              <div className="col md-6">
                <Field
                  name="phone"
                  component={FancyInput}
                  placeholder="Phone"
                />
              </div>

              <div className="col md-12">
                <Field
                  name="subject"
                  component={FancyInput}
                  placeholder="Subject"
                />
              </div>

              <div className="col sm-12">
                <Field
                  name="message"
                  component={TextArea}
                  placeholder="Your message *"
                />
              </div>

              <div className="col sm-12" style={{ marginBottom: "30px" }}>
                <Reaptcha
                  sitekey="6LcpsLwfAAAAAO9zzEUxSRQ7Kyaq4lDmDVCbrKGo"
                  onVerify={this.onReCaptchaVerify}
                  onExpire={this.onReCaptchaExpire}
                />
              </div>

              <div className="col sm-12">
                <input type="text" name="_gotcha" style={{ display: "none" }} />
                <FormSubmit
                  isSubmitting={isSubmitting}
                  disabled={!this.state.token || !isValid}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}
